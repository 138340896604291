import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Modal from '../Modal';

interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function LanguageModal({ isOpen, onClose }: LanguageModalProps) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    onClose();
  };

  const data = [
    { title: 'language_modal.english', value: 'en' },
    { title: 'language_modal.portuguese', value: 'pr' },
  ];

  return (
    <Modal
      isOpen={isOpen}
      width="600px"
      headerTextColor="w-full text-center !font-medium"
      title={t('language_modal.chooseLanguage')}
      body={
        <div className="overflow-y-auto pb-10">
          <div className="flex justify-center gap-6 pt-5">
            {data.map(item => {
              return (
                <div key={item.value}>
                  <Button
                    className={` px-10 py-4 font-airbnb text-sm font-medium text-black ${i18n.language === item.value && 'bg-dark-blue text-white'}`}
                    onClick={() => changeLanguage(item.value)}
                  >
                    {t(item.title)}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}
