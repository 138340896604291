import { zodResolver } from '@hookform/resolvers/zod';
import { Form as AntdForm } from 'antd';
import Button from 'app/components/Button';
import ConfirmationModal from 'app/components/ConfirmationModal';
import ImageUploader from 'app/components/ImageUploader';
import MultiSelect from 'app/components/MultiSelect';
import ProductSkeletonLoader from 'app/components/ProductListingSkelton';
import Tabs, { type Tab } from 'app/components/Tabs';
import SuccessIcon from 'assets/icons/sucessIcon.svg';
import {
  useGetProductTypes,
  usePublishListing,
  useUpdateListing,
} from 'hooks/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import type { ProductTypeFormData } from 'types/entities';
import type { z } from 'zod';

import { ProductTypeFormScheme } from '../../../constants/validationSchema';
import CategoryAndProductType from './CategoryAndProductType';
import GeneralInformation from './GeneralInformation';
import LocationField from './LocationField';
import PricingAndStock from './PricingAndStock';

type ProductListingFormType = z.infer<typeof ProductTypeFormScheme>;

const initialValues: ProductListingFormType = {
  title: '',
  description: '',
  category_uuid: '',
  images: [],
  availability_type: 'DAILY',
  // additional_notes: '',
  geolocation: {
    location: '',
    address: '',
    town: '',
    city: '',
    region: '',
    country: '',
    postal_code: 0,
  },
  product_details: [
    {
      product_type_uuid: '',
      pricing: {
        base_price: 0,
        stock: 0,
      },

      availability: [
        {
          start_datetime: '',
          end_datetime: '',
        },
      ],
    },
  ],
  status: '',
};
interface ProductListingProps {
  updateData?: ProductTypeFormData;
  loading?: boolean;
}

const ProductListing: React.FC<ProductListingProps> = ({
  updateData,
  loading = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: publishListing } = usePublishListing();
  const { mutate: updateListing } = useUpdateListing();
  const form = useForm<ProductListingFormType>({
    mode: 'onChange',
    resolver: zodResolver(ProductTypeFormScheme),
    defaultValues: initialValues,
  });

  const {
    formState: { errors },
    setValue,
    control,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    register,
  } = form;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabs, setTabs] = useState<Tab[]>([]);

  const {
    data: ProductTypes,
    isLoading: isProductTypeLoading,
    error: productTypeError,
    isSuccess: isProductTypesSuccess,
  } = useGetProductTypes({});

  useEffect(() => {
    if (updateData) {
      const updatedProductDetails = updateData.product_details.map(detail => ({
        ...detail,
        product_type_uuid: detail.product_type?.uuid || '',
        pricing: {
          base_price: detail.pricing?.base_price || 0,
          stock: detail.pricing?.stock || 0,
        },

        variants:
          detail?.variants
            ?.filter(varint => varint.variants_values?.length !== 0)
            ?.map(variant => ({
              uuid: variant.uuid || '',
              name: variant.name || '',
              is_deleted: variant.is_deleted || false,
              variants_values: variant.variants_values
                ? variant.variants_values.map(variantValue => ({
                    value: variantValue.value || '',
                    stock: variantValue.stock || 0,
                    price: variantValue.price || 0,
                    ...(variantValue.image
                      ? { image: variantValue.image }
                      : {}),
                    uuid: variantValue.uuid,
                    is_deleted: variantValue.is_deleted || false,
                  }))
                : [],
            })) || [],
        addons:
          detail.addons?.map(addon => ({
            name: addon.name || '',
            image: addon.image || '',
            description: addon.description || '',
            price: addon.price || 0,
            quantity: addon.quantity || 0,
            uuid: addon.uuid,
            is_deleted: addon.is_deleted || false,
          })) || [],
        specifications:
          detail.specifications?.map(specification => ({
            name: specification.name || '',
            value: specification.value || '',
            description: specification.description || '',
            display_on_product: specification.display_on_product || false,
            uuid: specification.uuid,
            is_deleted: specification.is_deleted || false,
          })) || [],
        availability:
          detail.availability?.map(availability => ({
            uuid: availability.uuid || '',
            start_datetime: availability.start_datetime || '',
            end_datetime: availability.end_datetime || '',
          })) || [],
      }));

      reset({
        title: updateData.title || '',
        description: updateData.description || '',
        category_uuid: updateData?.category_uuid || '',
        images: updateData.images || [],
        availability_type: updateData.availability_type || '',
        additional_notes: updateData.additional_notes || '',
        geolocation: updateData.geolocation || {
          location: '',
          address: '',
          town: '',
          city: '',
          region: '',
          country: '',
          postal_code: 0,
        },
        product_details: updatedProductDetails,
        status: updateData.status || '',
      });

      setSelectedItems(
        updatedProductDetails.map(detail => detail.product_type_uuid),
      );
    }
  }, [updateData, reset]);
  useEffect(() => {
    const updatedTabs = selectedItems.map((item, index) => {
      const itemLabel =
        ProductTypes?.data.find((p: { uuid: string }) => p?.uuid === item)
          ?.title || item;
      return {
        id: `tab-${index}`,
        label: itemLabel,
        content: (
          <PricingAndStock
            key={item}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            index={index}
            updatedData={updateData}
          />
        ),
      };
    });
    setTabs(updatedTabs);

    setActiveTabIndex(updatedTabs.length - 1);
  }, [selectedItems, ProductTypes?.data, errors]);

  const onSubmit: SubmitHandler<ProductListingFormType> = (data, e: any) => {
    const buttonId = e?.nativeEvent?.submitter?.id;
    const status = buttonId === 'draft' ? 'DRAFT' : 'IN_REVIEW';

    // for (const detail of data?.product_details || []) {
    //   if (Array.isArray(detail.variants)) {
    //     for (const variant of detail.variants) {
    //       if (
    //         variant &&
    //         variant.name?.trim() &&
    //         !variant.variants_values?.some(val => val.value?.trim())
    //       ) {
    //         ShowToastNotification('Variants-value-Required.error', 'error');
    //         return;
    //       }
    //     }
    //   }

    //   if (!detail.availability || detail.availability.length === 0) {
    //     ShowToastNotification('date_error', 'error');
    //     return;
    //   }
    // }

    const payload = {
      basic_info: {
        title: data.title,
        description: data.description,
        category_uuid: data.category_uuid,
        images: data.images,
        additional_notes: data.additional_notes,
        availability_type: data.availability_type,
        geolocation: {
          ...data.geolocation,
          location: `POINT(${data.geolocation?.location})`,
        },
      },
      product_details: data?.product_details?.map((detail: any) => ({
        ...detail,
        product_type_uuid: detail.product_type_uuid || '',
        availability:
          detail.availability?.filter(
            (item: any) =>
              item?.start_datetime?.length > 0 &&
              item?.end_datetime?.length > 0,
          ) || [],
        // variants: detail.variants.filter((variant: any) =>
        //   variant.name?.trim(),
        // ),
      })),
      status,
    };

    const onSuccess = () => {
      setModalMessage(
        updateData
          ? t('ProductListing.update_message')
          : t('ProductListing.submit_message'),
      );
      setIsModalOpen(true);
    };

    if (updateData?.uuid) {
      updateListing({ payload, uuid: updateData.uuid }, { onSuccess });
    } else {
      publishListing(payload, { onSuccess });
    }
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/product-listings');
  };

  const multiOptions = useMemo(() => {
    if (isProductTypesSuccess && ProductTypes) {
      return Array.isArray(ProductTypes.data)
        ? ProductTypes.data.map((product: any) => ({
            label: product.title,
            value: product.uuid,
          }))
        : [];
    }
    return [];
  }, [ProductTypes, isProductTypesSuccess]);

  const handleMultiSelectChange = (updatedItems: string[]) => {
    const deselectedItems = selectedItems.filter(
      item => !updatedItems.includes(item),
    );

    setSelectedItems(updatedItems);

    const updatedProductDetails = updatedItems.map(product_type_uuid => {
      const existingDetail = watch('product_details')?.find(
        detail => detail.product_type_uuid === product_type_uuid,
      );

      return (
        existingDetail || {
          product_type_uuid,
          pricing: { base_price: 0, stock: 0 },
          variants: [],
          addons: [],
          specifications: [],
          availability: [],
        }
      );
    });

    setValue('product_details', updatedProductDetails);

    deselectedItems.forEach(item => {
      const index = selectedItems.indexOf(item);
      if (index > -1) {
        clearErrors(`product_details.${index}`);
      }
    });

    const updatedTabs = updatedItems.map((item, index) => {
      const itemLabel =
        ProductTypes?.data.find((p: any) => p?.uuid === item)?.title || item;
      return {
        id: `tab-${index}`,
        label: itemLabel,
        content: (
          <PricingAndStock
            key={item}
            errors={errors}
            setValue={setValue}
            watch={watch}
            index={index}
            register={register}
            updatedData={updateData}
          />
        ),
      };
    });

    setTabs(updatedTabs);

    setActiveTabIndex(updatedTabs.length > 0 ? updatedTabs.length - 1 : 0);
  };

  if (isProductTypeLoading || loading) {
    return <ProductSkeletonLoader />;
  }

  if (productTypeError) {
    return (
      <p className="text-red-600">{t('ProductListing.error_loading_data')}</p>
    );
  }

  return (
    <>
      <div className="rounded-xl border border-gray-300 bg-white p-7 shadow-md">
        <div className="flex flex-row justify-between pb-5">
          <div className="flex flex-col">
            <div className="text-lg font-bold">
              {t('addProduct.productInformation')}
            </div>
            <div className="flex flex-row">
              <Link to="/">{t('addProduct.dashboard')}</Link>
              <span className="px-2">/</span>
              <div className="text-blue-800">
                <Link to="/add-product">{t('addProduct.addProduct')}</Link>
              </div>
            </div>
          </div>
        </div>
        <FormProvider {...form}>
          <AntdForm
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
          >
            <div className="w-full md:col-span-2 lg:col-span-2">
              <GeneralInformation
                control={control}
                errors={errors}
                register={register}
              />
              <div className="h-auto w-full">
                <CategoryAndProductType
                  value={watch('category_uuid')}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                  selected={selectedItems}
                  onChange={setSelectedItems}
                  register={register}
                  data={ProductTypes}
                />
                <div className="pt-10">
                  <MultiSelect
                    id="product_type_uuid"
                    options={multiOptions}
                    label={t('ProductListing.select_product_type')}
                    placeholder={t('ProductListing.select_product_type')}
                    onChange={handleMultiSelectChange}
                    selectedItems={selectedItems}
                    errors={errors}
                  />
                  {errors?.product_details?.[activeTabIndex]
                    ?.product_type_uuid && (
                    <p className="text-red-600">
                      {' '}
                      {t('ProductListing.Please_select_product_type')}
                    </p>
                  )}
                  <div className="w-full">
                    <label
                      htmlFor="address"
                      className="mt-6 block text-lg font-medium text-gray-600"
                    >
                      {t('ProductListing.location')}
                      <span className="text-red-600">*</span>
                    </label>
                    <LocationField updateData={updateData} />
                  </div>{' '}
                </div>
              </div>
              <div className="mb-6 size-auto">
                <div className="flex h-auto w-full justify-center">
                  <div className="my-14 w-full">
                    <Tabs
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onTabChange={setActiveTabIndex}
                    />
                  </div>
                </div>
              </div>
              {/* <div className=" min-h-7">
                <label className="mb-4 mt-6 block text-lg font-semibold text-gray-600">
                  {t('ProductListing.additional-notes')}
                </label> */}

              {/* <textarea
                  rows={5}
                  placeholder={t('ProductListing.additional-notes')}
                  className="h-[200px] w-full resize-none rounded-lg border border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register(`additional_notes`)}
                /> */}
            </div>
            {/* </div> */}

            <div className="w-full pt-6 md:col-span-1 lg:col-span-1">
              <ImageUploader
                name="product_images"
                label={t('ProductListing.upload_product_image')}
                updatedImage={updateData?.images}
              />
            </div>
            <div className="mt-2 flex justify-end space-x-7 md:col-span-2 lg:col-span-2">
              <Button htmlType="reset" id="draft">
                {t('ProductListing.save_drafts')}
              </Button>

              <Button
                htmlType="submit"
                className=" h-[42px] min-w-[140px] rounded-xl bg-blue-600 px-5 text-white hover:text-black lg:min-w-[153px]"
              >
                {updateData
                  ? t('ProductListing.update')
                  : t('ProductListing.submit')}
              </Button>
            </div>
          </AntdForm>
        </FormProvider>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        width="684px"
        height="269px"
        headerTitle="Confirmation"
        containerClassName="z-20 "
        headerClassName="rounded-t-2xl"
        overFlow="overflow-y-auto"
      >
        <div className="mt-5 flex flex-col items-center justify-center p-4 text-center font-airbnb">
          <img src={SuccessIcon} alt="success" className="justify-center" />
          <p className="text-dark-green mt-3 text-[20px]">{modalMessage}</p>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ProductListing;
