import { z } from 'zod';

export const ProductTypeFormValidation = z.object({
  // title: z.string().min(1, { message: 'Title is required' }),
  title: z.string().min(1, { message: 'Title is required' }),

  description: z.string().min(1, { message: 'Description is required' }),
});
export const CategoriesFormValidation = z.object({
  title: z.string().min(1, { message: 'Category name is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  image: z.string(),
  banner_image: z.string().nullable().optional(),
  category_uuid: z.string().optional(),
  parent_category: z.string().optional(),
  parent_category_title: z.string().optional(),
});
export const DocumentFormValidation = z.object({
  title: z.string().min(1, { message: 'Document is required' }),
  verification_required: z.boolean(),
  is_active: z.boolean(),
});

export type FormValidationType = z.infer<typeof CategoriesFormValidation>;
export type DocumentFormValidationType = z.infer<typeof DocumentFormValidation>;
export const variantValueSchema = z
  .object({
    uuid: z.string().optional(),
    value: z.string().min(1, 'Value is required'),
    price: z.number().min(0, 'Price must be a positive number'),
    stock: z.number().min(0, 'Stock must be a positive number'),
    image: z.string().optional(),
    is_deleted: z.boolean().optional(),
  })
  .optional();

// export const variantSchema = z.object({
//   uuid: z.string().optional(),
//   name: z.string().optional(),
//   variants_values: z.array(variantValueSchema).optional(),
// });
const scheduleSchema = z.array(
  z
    .object({
      start_datetime: z.string().optional(),
      end_datetime: z.string().optional(),
      uuid: z.string().optional(),
      is_deleted: z.boolean().optional(),
    })

    .optional(),
);

const ProductTypeSchema = z.object({
  product_type_uuid: z
    .string()
    .min(1, { message: 'Please select a product type' }),
  pricing: z
    .object({
      base_price: z.number().min(1, { message: 'Base Price is required' }),
      stock: z.number(),
    })
    .optional(),

  // variants: z.array(
  //   z
  //     .object({
  //       name: z.string().optional(),
  //       quantity: z.number().nullable().optional(),
  //       uuid: z.string().min(1, 'UUID is required').optional(),
  //       variants_values: z.array(
  //         z.object({
  //           uuid: z.string().optional(),
  //           value: z.string().optional(),
  //           price: z.number().optional(),
  //           stock: z.number().optional(),
  //           image: z.string().optional(),
  //           is_deleted: z.boolean().optional(),
  //         }),
  //       ),
  //     })
  //     .optional(),
  // ),
  // addons: z.array(
  //   z.object({
  //     uuid: z.string().min(1, 'UUID is required').optional(),
  //     name: z.string().min(1, 'Name is required'),
  //     price: z.number().positive('Price must be positive'),
  //     description: z.string().optional(),
  //     quantity: z.number().optional(),
  //     image: z.string().nullable().optional(),
  //     is_deleted: z.boolean().optional(),
  //   }),
  // ),

  // specifications: z.array(
  //   z
  //     .object({
  //       name: z.string().min(1, { message: 'Name is required' }),
  //       value: z.string().min(1, { message: 'Value is required' }),
  //       description: z.string().nullable().optional(),
  //       display_on_product: z.boolean().optional(),
  //       uuid: z.string().optional(),
  //       is_deleted: z.boolean().optional(),
  //     })
  //     .optional(),
  // ),
  availability: scheduleSchema.optional(),
});

export const ProductTypeFormScheme = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  category_uuid: z.string().min(1, { message: 'Please select a category' }),
  images: z.array(z.string().min(1, { message: 'Image is Required' })),
  status: z.string().optional(),
  additional_notes: z.string().optional(),
  availability_type: z.string().optional(),

  geolocation: z
    .object({
      location: z.string().min(1, { message: 'Location is required' }),
      address: z.string().optional(),
      town: z.string().optional(),
      city: z.string().optional(),
      region: z.string().optional(),
      country: z.string().optional(),
      postal_code: z.number().optional(),
    })
    .required(),
  product_details: z.array(ProductTypeSchema).optional(),
});
