import 'react-toastify/dist/ReactToastify.css';

import i18n from 'i18next';
import { toast } from 'react-toastify';

type ToastType = 'success' | 'error' | 'warning' | 'info';

export const ShowToastNotification = (
  key: string,
  type: ToastType = 'success',
) => {
  const message = i18n.t(`restApiNotifications.${key}`);
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'error':
      toast.error(message);
      break;
    case 'warning':
      toast.warning(message);
      break;
    case 'info':
      toast.info(message);
      break;
    default:
      toast.success(message);
      break;
  }
};
