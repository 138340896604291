import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShowToastNotification } from 'utils/ToastNotification/toastNotification';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import DeleteIcon from './../../../assets/icons/deleteIcon.svg';

interface SchedulingProps {
  setValue: ReturnType<typeof useFormContext>['setValue'];
  index: number;
  watch: any;
}

const Scheduling: React.FC<SchedulingProps> = ({ setValue, index, watch }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const [data, setData] = useState<any[]>([]);

  const watchedAvailabilityData = watch(
    `product_details[${index}].availability`,
  );
  const initialAvailabilityData =
    getValues(`product_details[${index}].availability`) || [];

  useEffect(() => {
    if (watchedAvailabilityData && watchedAvailabilityData.length > 0) {
      setData(watchedAvailabilityData);
    } else if (initialAvailabilityData.length > 0) {
      setData(initialAvailabilityData);
    } else {
      setData([
        {
          id: Math.random() * 1000,
          start_datetime: null,
          end_datetime: null,
          is_deleted: false,
        },
      ]);
    }
  }, [watchedAvailabilityData, initialAvailabilityData, index]);

  const handleAdd = () => {
    if (data.some(item => !item.start_datetime || !item.end_datetime)) {
      ShowToastNotification('CompleteDateFields', 'error');
      return;
    }
    if (!data.map(item => item.start_datetime || !item.end_datetime)) {
      ShowToastNotification('CompleteDateFields', 'error');

      return;
    }
    const newEntry = {
      id: Math.floor(Math.random() * 1000),
      start_datetime: '',
      end_datetime: '',
      is_deleted: false,
    };

    const updatedData = [...data, newEntry];
    setData(updatedData);
    setValue(`product_details[${index}].availability`, updatedData);
  };

  const handleDateChange = (
    field: 'start_datetime' | 'end_datetime',
    value: Dayjs | null,
    entryIndex: number,
  ) => {
    if (!value) {
      ShowToastNotification('DateEmpty', 'error');
      return;
    }

    const updatedData = [...data];

    const currentItem = updatedData[entryIndex];

    if (
      field === 'end_datetime' &&
      value.isBefore(dayjs(currentItem.start_datetime))
    ) {
      ShowToastNotification('EndDateTime', 'error');
      return;
    }

    updatedData[entryIndex] = {
      ...updatedData[entryIndex],
      [field]: value ? value.toISOString() : null,
    };

    setData(updatedData);
    setValue(`product_details[${index}].availability`, updatedData);
  };

  const handleDelete = (itemIndex: number) => {
    const updatedData = data.filter((_, idx) => idx !== itemIndex);
    setData(updatedData);
    setValue(`product_details[${index}].availability`, updatedData);

    const itemToRemove = data[itemIndex];
    const isExistingItem = itemToRemove?.uuid;

    if (isExistingItem) {
      const updatedDataSec = data.map((addon: any, idx: number) =>
        idx === itemIndex ? { ...addon, is_deleted: true } : addon,
      );
      setData(updatedDataSec);
      setValue(`product_details[${index}].availability`, updatedDataSec);
    } else {
      const updatedDataSec = data.filter(
        (_: any, i: number) => i !== itemIndex,
      );
      setData(updatedDataSec);
      setValue(`product_details[${index}].availability`, updatedDataSec);
    }
  };

  return (
    <div className="mb-10">
      <div className="flex flex-col md:flex-row">
        <div className="mt-12 flex h-[55px] w-1/5 rounded-r-xl bg-light-gray ">
          <h1 className="items-center justify-between p-4 text-[16px] font-bold">
            {t('ProductListing.scheduling')}
          </h1>
        </div>
        <div className="mx-5 mt-6 w-full">
          {data
            .filter(item => !item.is_deleted)
            .map((item, entryIndex) => (
              <div
                className="mt-4 flex flex-col md:flex-row md:items-center md:space-x-4"
                key={item.id}
              >
                <div className="mb-2 flex flex-col items-center justify-center border-none md:flex-row md:space-x-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={t('ProductListing.start_date')}
                      format="YYYY/MM/DD HH:mm"
                      value={
                        item.start_datetime ? dayjs(item.start_datetime) : null
                      }
                      timeSteps={{ hours: 2, minutes: 30, seconds: 60 }}
                      onChange={newValue =>
                        handleDateChange('start_datetime', newValue, entryIndex)
                      }
                    />
                    <DateTimePicker
                      label={t('ProductListing.end_date')}
                      format="YYYY/MM/DD HH:mm"
                      value={
                        item.end_datetime ? dayjs(item.end_datetime) : null
                      }
                      timeSteps={{ hours: 2, minutes: 30, seconds: 60 }}
                      onChange={newValue =>
                        handleDateChange('end_datetime', newValue, entryIndex)
                      }
                      minDate={
                        item.start_datetime
                          ? dayjs(item.start_datetime).startOf('day')
                          : undefined
                      }
                    />
                  </LocalizationProvider>
                </div>
                {data.length > 1 && (
                  <img
                    src={DeleteIcon}
                    alt="deleteIcon"
                    width={34}
                    height={50}
                    className="h-[30px] cursor-pointer"
                    onClick={() => handleDelete(entryIndex)}
                  />
                )}
                {entryIndex === data.length - 1 && (
                  <img
                    src={AddIcon}
                    alt="add"
                    width={34}
                    height={34}
                    className="cursor-pointer"
                    onClick={handleAdd}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Scheduling;
