import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DocumentFormValidationType } from 'constants/validationSchema';
import {
  acceptPayment,
  addProductPost,
  deleteCategory,
  deleteDocumentType,
  deleteProduct,
  deleteProductType,
  getAllInfluencer,
  getCategories,
  getDocumentTypes,
  getMyOrders,
  getPaymentHistory,
  getPaymentMethods,
  getProductDetails,
  getProductId,
  getProducts,
  getProductTypes,
  getSettings,
  getUsers,
  postCategory,
  postDocumentType,
  postProductTypes,
  publishProduct,
  registerUser,
  rejectPayment,
  rejectProductListing,
  restoreDeletedProduct,
  togglePaymentMethodStatus,
  updateCategory,
  updateDocumentType,
  updateProduct,
  updateProductListing,
  updateProductType,
  updatePublishProduct,
  updateSettings,
  updateUsers,
  UploadImage,
} from 'services/restApis';
import { searchAddons } from 'services/restApis';
import type { PaginationProps, Product } from 'types/entities';
import type { SearchAddon } from 'types/requestsAndResponses';
import type { ResponseError } from 'utils/axios';

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;
export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    ResponseError,
    Parameters<MutationFnType>[0]
  >;
export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;
export const usePostProductTypes = (
  config: MutationConfig<typeof postProductTypes> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['postProductTypes'],
    mutationFn: data => postProductTypes(data),
  });
};
export const useUpdateProductType = (
  config: MutationConfig<typeof updateProductType> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateProductType'],
    mutationFn: ({ data, productId }) => updateProductType(data, productId),
  });
};

export const useDeleteProduct = (
  config: MutationConfig<typeof deleteProduct> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['deleteProduct'],
    mutationFn: ({ data, productId }) => deleteProduct(data, productId),
  });
};

export const usePostCategory = (
  config: MutationConfig<typeof postCategory> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['postCategory'],
    mutationFn: data => postCategory(data),
  });
};
export const useUpdateCategory = (
  config: MutationConfig<typeof updateCategory> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateCategory'],
    mutationFn: updateCategory,
  });
};

export const useUpdateListing = (
  config: MutationConfig<typeof updatePublishProduct> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updatePublishProduct'],
    mutationFn: ({ uuid, payload }: { uuid: string; payload: any }) =>
      updatePublishProduct({ uuid, payload }),
  });
};

export const useUpdateProduct = (
  config: MutationConfig<typeof updateProduct> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateProduct'],
    mutationFn: ({ uuid, payload }) => updateProduct(uuid, payload),
  });
};

export const useDeleteCategory = (
  config: MutationConfig<typeof deleteCategory> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['deleteCategory'],
    mutationFn: ({ data, categoryId }) => deleteCategory(data, categoryId),
  });
};

export const useGetProductId = (productId: string) =>
  useQuery({
    queryKey: ['getProductId', productId],
    queryFn: () => getProductId(productId),
  });

export const useGetProducts = (
  {
    currentPage,
    pageSize,
    filter,
    searchValue,
    isDeleted,
  }: PaginationProps & { searchValue?: string; isDeleted?: boolean },
  config: QueryConfig<typeof getProducts> = {},
) =>
  useQuery({
    ...config,
    queryKey: [
      'getProducts',
      currentPage,
      pageSize,
      filter,
      searchValue,
      isDeleted,
    ],
    queryFn: () =>
      getProducts({ currentPage, pageSize, filter, searchValue, isDeleted }),
  });
export const useRegisterUser = () =>
  useMutation({
    mutationKey: ['register'],
    mutationFn: registerUser,
  });

export const useAddProduct = (
  config: MutationConfig<typeof deleteCategory> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['addProduct'],
    mutationFn: addProductPost,
  });
};

export const useGetCategories = (
  { currentPage, pageSize, filter }: PaginationProps,
  config: QueryConfig<typeof getCategories> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['categories', currentPage, pageSize, filter],
    queryFn: () => getCategories({ currentPage, pageSize, filter }),
  });
};

export const useGetProductTypes = (
  { currentPage, pageSize, filter }: PaginationProps,
  config: QueryConfig<typeof getProductTypes> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getProductTypes'],
    queryFn: () => getProductTypes({ currentPage, pageSize, filter }),
  });
};

export const useDeleteProductType = (
  config: MutationConfig<typeof deleteProductType> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['deleteProductType'],
    mutationFn: (productTypeId: string) => deleteProductType(productTypeId),
  });
};
export const useUpdateProductListing = (
  config: MutationConfig<typeof updateProductListing> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateProductListing'],
    mutationFn: updateProductListing,
  });
};

export const useRejectProductListing = (
  config: MutationConfig<typeof rejectProductListing> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['rejectProductListing'],
    mutationFn: (data: { uuid: string; decline_reason: string }) =>
      rejectProductListing(data),
  });
};

// get product detail
export const useGetProductDetails = (id: string) => {
  return useQuery<Product, ResponseError>({
    queryKey: ['getProductDetails', id],
    queryFn: () => getProductDetails(id),
  });
};

export const useUploadImage: any = (
  config: MutationConfig<typeof UploadImage> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['uploadImage'],
    mutationFn: UploadImage,
  });
};

export const usePublishListing: any = (
  config: MutationConfig<typeof publishProduct> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['publishListing'],
    mutationFn: publishProduct,
  });
};

export const useGetDocumentTypes = (
  { currentPage, pageSize }: PaginationProps,
  config: QueryConfig<typeof getDocumentTypes> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getDocumentTypes'],
    queryFn: () => getDocumentTypes({ currentPage, pageSize }),
  });
};

export const useDeleteDocumentType = (
  config: MutationConfig<typeof deleteDocumentType> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['deleteDocumentType'],
    mutationFn: (documentTypeId: string) => deleteDocumentType(documentTypeId),
  });
};

export const usePostDocumentType = (
  config: MutationConfig<typeof postDocumentType> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['postDocumentType'],
    mutationFn: (data: DocumentFormValidationType) => postDocumentType(data),
  });
};
export const useUpdateDocumentType = (
  config: MutationConfig<typeof updateDocumentType> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateDocumentType'],
    mutationFn: updateDocumentType,
  });
};

export const useGetMyOrders = (
  { currentPage, pageSize }: PaginationProps,
  config: QueryConfig<typeof getMyOrders> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getMyOrders', currentPage, pageSize],
    queryFn: () => getMyOrders({ currentPage, pageSize }),
  });
};
export const useSearchAddons = (searchQuery: string) => {
  return useQuery<SearchAddon, ResponseError>({
    queryKey: ['searchAddons', searchQuery],
    queryFn: () => searchAddons(searchQuery),
    enabled: true,
  });
};

export const useGetSettings = (
  config: QueryConfig<typeof getSettings> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getSettings'],
    queryFn: () => getSettings(),
  });
};
export const useUpdateSettings = (
  config: MutationConfig<typeof updateSettings> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateSettings'],
    mutationFn: updateSettings,
  });
};

// delete product

export const useRestoreDeletedProduct = (
  config: MutationConfig<typeof restoreDeletedProduct> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['restoreDeletedProduct'],
    mutationFn: (productId: string) => restoreDeletedProduct(productId),
  });
};

// get users

export const useGetUsers = (
  { currentPage, pageSize, filter }: PaginationProps,
  config: QueryConfig<typeof getUsers> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getUsers', currentPage, pageSize, filter],
    queryFn: () => getUsers({ currentPage, pageSize, filter }),
  });
};

// update users

export const useUpdateUsers = (
  config: MutationConfig<typeof updateUsers> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['updateUsers'],
    mutationFn: updateUsers,
  });
};

export const useGetPaymentHistory = (
  { currentPage, pageSize }: PaginationProps,
  config: QueryConfig<any> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getPaymentHistory', currentPage, pageSize],
    queryFn: () => getPaymentHistory({ currentPage, pageSize }),
  });
};

export const useAcceptPayment = (config: MutationConfig<any> = {}) => {
  return useMutation({
    ...config,
    mutationKey: ['acceptPayment'],
    mutationFn: acceptPayment,
  });
};

export const useRejectPayment = (config: MutationConfig<any> = {}) => {
  return useMutation({
    ...config,
    mutationKey: ['acceptPayment'],
    mutationFn: rejectPayment,
  });
};

export const useGetAllInfluencer = (
  { currentPage, pageSize, username }: PaginationProps,
  config: QueryConfig<any> = {},
) => {
  return useQuery({
    ...config,
    queryKey: ['getAllInfluencer', currentPage, pageSize, username],
    queryFn: () => getAllInfluencer({ currentPage, pageSize, username }),
  });
};

export const useGetPaymentMethods = (config: QueryConfig<any> = {}) => {
  return useQuery({
    ...config,
    queryKey: ['getPaymentMethods'],
    queryFn: () => getPaymentMethods(),
  });
};

export const useTogglePaymentMethodStatus = (
  config: MutationConfig<typeof togglePaymentMethodStatus> = {},
) => {
  return useMutation({
    ...config,
    mutationKey: ['togglePaymentMethodStatus'],
    mutationFn: (variables: { is_active: boolean; payment_method: string }) =>
      togglePaymentMethodStatus(variables),
  });
};
