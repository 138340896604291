import { Switch } from 'antd';
import Table from 'app/components/Table';
import {
  useGetPaymentMethods,
  useTogglePaymentMethodStatus,
} from 'hooks/queries';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToastNotification } from 'utils/toastNotification';

export const PaymentListing = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    () => Number(localStorage.getItem('currentPage')) || 1,
  );

  const { data, isLoading, refetch } = useGetPaymentMethods();

  const { mutateAsync } = useTogglePaymentMethodStatus();
  useEffect(() => {
    localStorage.setItem('currentPage', String(currentPage));
  }, [currentPage]);

  const changePaymentStatus = async (
    status: boolean,
    payment_method: string,
  ) => {
    try {
      await mutateAsync({
        is_active: !status,
        payment_method: payment_method,
      });
      refetch();
    } catch (error: any) {
      showToastNotification(
        '',
        'error',
        error?.response?.data?.detail as string,
      );
    }
  };

  const columns = [
    {
      title: t('columns_header.payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '80%',
    },
    {
      title: t('columns_header.payment_Status'),
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'right',
      render: (status: boolean, record: any) => (
        <Switch
          defaultChecked={status}
          size="small"
          className="float-right"
          onClick={() => changePaymentStatus(status, record?.payment_method)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data?.data?.length > 0 ? data?.data : []}
      pagination
      isLoading={isLoading}
      pageSize={pageSize}
      currentPage={currentPage}
      onPageChange={(page, size) => {
        setCurrentPage(page);
        if (size) setPageSize(size);
      }}
    />
  );
};
